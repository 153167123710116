
import { Component, Vue } from 'vue-property-decorator'
import AMapLoader from '@amap/amap-jsapi-loader'
import { mapKey } from '@/utils/config'
import { ElForm } from 'element-ui/types/form'
import { drawPolygon } from '@/utils/formatData'
import { twoDecimalNoZero } from '@/utils/validate'
import { PolygonStyle } from '@/types/common'

type Lnglat = {
  longitude: number;
  latitude: number;
}

@Component
export default class IrrigationArea extends Vue {
  private status = ''
  private mouseTool: any = null
  private overlays: Array<any> = []
  private info: any = {
    areaName: '',
    areaExtent: '',
    projectId: this.projectId,
    locationList: [],
    valveList: '', // 电磁阀列表
    soilList: '', // 土壤列表
    waterList: '', // 水电列表
    electricityList: '' // 电表列表
  }

  private max9Length = (rule: object, value: string, callback: Function) => {
    if (value && value.split('.')[0] && value.split('.')[0].length > 9) {
      callback(new Error('请输入9位以内的数!'))
    } else {
      callback()
    }
  }

  private rules = {
    areaName: [{ required: true, message: '请输入区域名称', trigger: ['change'] }],
    areaExtent: [{ validator: twoDecimalNoZero, trigger: ['blur', 'change'] }, { validator: this.max9Length, trigger: ['blur', 'change'] }],
    valveList: [{ required: true, message: '请选择电磁阀', trigger: ['blur'] }]
  }

  // 电磁阀
  private valveList = []
  private soilList = []
  private waterList = []
  private electricityList = []
  private map: AMap.Map | any = null
  private submitShow = false
  private ploygons: Array<AMap.Polygon> = []
  private selectPolygon: { ind: number; polygon: AMap.Polygon | null } = { ind: -1, polygon: null }
  private polygonSelectStyle: PolygonStyle = {
    strokeColor: '#FF402B',
    fillColor: '#FF402B',
    strokeWeight: 4,
    strokeOpacity: 1,
    fillOpacity: 0.5,
    strokeStyle: 'solid'
  }

  private polygonCurrentStyle: PolygonStyle = {
    strokeColor: '#9D4DFF',
    fillColor: '#9D4DFF',
    strokeWeight: 4,
    strokeOpacity: 1,
    fillOpacity: 0.5,
    strokeStyle: 'solid',
    cursor: 'pointer',
    zIndex: 1000
  }

  private polygonStyle: PolygonStyle = {
    strokeColor: '#3592FE',
    fillColor: '#3592FE',
    strokeWeight: 4,
    strokeOpacity: 1,
    fillOpacity: 0.3,
    strokeStyle: 'solid',
    zIndex: 100
  }

  get areaId () {
    return this.$route.params.id
  }

  get projectId () {
    return this.$route.query.projectId
  }

  created () {
    this.selectIrrigationValveByList()
    this.selectIrrigationSoilByList()
    this.selectIrrigationWaterByList()
    this.selectIrrigationElectricityByList()
    this.loadData().then(() => {
      if (this.areaId) {
        this.getDetail()
      }
    })
  }

  getBindName (item: any) {
    return item.areaId ? item.deviceNumber + '(已绑定)' : item.deviceNumber
  }

  // 喷灌电磁阀
  selectIrrigationValveByList () {
    this.$axios.get(this.$apis.irrigation.selectIrrigationValveByList, {
      ...this.getParams(),
      projectId: this.projectId
    }).then((res) => {
      this.valveList = res || []
    })
  }

  // 喷灌土壤设备
  selectIrrigationSoilByList () {
    this.$axios.get(this.$apis.irrigation.selectIrrigationSoilByList, {
      ...this.getParams(),
      projectId: this.projectId
    }).then((res) => {
      this.soilList = res || []
    })
  }

  // 喷灌水表设备
  selectIrrigationWaterByList () {
    this.$axios.get(this.$apis.irrigation.selectIrrigationWaterByList, {
      ...this.getParams(),
      projectId: this.projectId
    }).then((res) => {
      this.waterList = res || []
    })
  }

  // 查询喷灌电表设备列表
  selectIrrigationElectricityByList () {
    this.$axios.get(this.$apis.irrigation.selectIrrigationElectricityByList, {
      ...this.getParams(),
      projectId: this.projectId
    }).then((res) => {
      this.electricityList = res || []
    })
  }

  getParams () {
    return this.areaId ? {} : {}
  }

  // 获取电子围栏
  loadData () {
    return new Promise((resolve, reject) => {
      this.$axios.get(this.$apis.project.selectYhProjectMapByProjectId, {
        projectId: this.projectId
      }).then((res) => {
        !this.map && this.loadMap({ latitude: res.latitude, longitude: res.longitude }, res.locationList)
        resolve(res)
      }).catch((res) => {
        reject(res)
      })
    })
  }

  // 区域详情
  getDetail () {
    this.$axios.get(this.$apis.irrigation.selectIrrigationAreaByAreaId, {
      areaId: this.areaId
    }).then((res) => {
      this.info = res
      if (this.map) {
        this.overlays = drawPolygon(this.map, res.locationList, this.polygonStyle, null)
      }
    })
  }

  // 地图初始化
  loadMap (projectInfo: {longitude: number; latitude: number}, list: Array<{ longitude: number; latitude: number; fenceCode: string }>) {
    AMapLoader.load({
      key: mapKey,
      version: '2.0',
      plugins: [
        'AMap.MouseTool'
      ]
    }).then((AMap) => {
      const center: [number, number] = [Number(projectInfo.longitude), Number(projectInfo.latitude)]
      const map = new AMap.Map('container', {
        center: center,
        zoom: 11
      })
      this.map = map
      drawPolygon(this.map, list, null, null)
      this.mouseTool = new AMap.MouseTool(this.map)
      this.mouseTool.on('draw', (e: any) => {
        // 面积计算，清除掉面积为0的非围栏所绘线条，四舍五入，面积小于0.5平方米的也舍弃
        const area = Math.round(AMap.GeometryUtil.ringArea(e.obj.getPath()))
        if (area > 0) {
          this.overlays.push(e.obj)
        } else {
          this.map.remove(e.obj)
          this.$message.warning('当前所画图形面积为0，无法保存。')
        }
      })
    })
  }

  // 选中
  choosePolyon () {
    this.status = 'choose'
    this.mouseTool.close()
    this.overlays.forEach(item => {
      item.on('click', this.polygonClick)
    })
  }

  // 绘制区域围栏
  drawAreaPolyon () {
    this.status = 'draw'
    this.overlays.forEach(item => {
      item.off('click', this.polygonClick)
    })
    this.mouseTool.polygon(this.polygonStyle)
  }

  polygonClick (e: any) {
    if (this.status === 'choose') {
      const ind = this.overlays.findIndex(item => item.contains(e.lnglat))
      if (ind >= 0) {
        this.selectPolygon.polygon && this.selectPolygon.polygon.setOptions(this.polygonStyle)
        this.selectPolygon.ind = ind
        this.selectPolygon.polygon = this.overlays[ind] as AMap.Polygon
        this.selectPolygon.polygon.setOptions(this.polygonSelectStyle)
      }
    }
  }

  // 清除选中的围栏
  clearSelectPolygon () {
    this.status = 'delete'
    if (this.selectPolygon.ind >= 0) {
      this.$confirm('确定要删除选中的围栏？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.status = ''
        this.removePolygon()
      })
    } else {
      this.$message.warning('请先选中要删除的区域围栏！')
    }
  }

  // 从数组中清除选中的电子围栏
  removePolygon () {
    this.$message.success('删除围栏成功')
    this.map && this.map.remove(this.selectPolygon.polygon)
    this.overlays.splice(this.selectPolygon.ind, 1)
    this.selectPolygon = { ind: -1, polygon: null }
  }

  onSubmit () {
    (this.$refs.info as ElForm).validate((valid) => {
      if (valid) {
        const location: Array<{latitude: number; longitude: number; fenceCode: number}> = []
        this.overlays.forEach((item: any, index: number) => {
          const path: Array<{ lng: number; lat: number }> = item.getPath()
          path.forEach((cItem: { lng: number; lat: number }) => {
            location.push({
              latitude: cItem.lat,
              longitude: cItem.lng,
              fenceCode: index
            })
          })
        })
        this.info.locationList = location
        this.submitShow = true
        const url = this.areaId ? this.$apis.irrigation.updateIrrigationArea : this.$apis.irrigation.insertIrrigationArea
        this.$axios.post(url, {
          ...this.info
        }).then(() => {
          this.$message({ message: '保存成功', type: 'success' })
          this.$router.push({
            path: '/irrigation/detail/manage',
            query: {
              projectId: this.projectId
            }
          })
        }).catch((err) => {
          this.submitShow = false
          this.$message(err)
        })
      }
    })
  }
}
